/* unplugin-vue-components disabled */import __unplugin_components_2 from 'C:/docker/118_meijia/frontend/src/components/form/FormItem.vue';
import __unplugin_components_1 from 'C:/docker/118_meijia/frontend/src/components/form/UInput.vue';
import __unplugin_components_0 from 'C:/docker/118_meijia/frontend/src/components/MJTags.vue';
import "core-js/modules/es.function.name.js";
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref, mergeProps as _mergeProps, createSlots as _createSlots, createBlock as _createBlock } from "vue";
var _hoisted_1 = ["innerHTML"];
var _hoisted_2 = {
  key: 0,
  class: "m-tag-fake-input__multiple-item-count"
};
var _hoisted_3 = {
  key: 0,
  class: "m-tag-fake-input__placeholder"
};
import { useField, ErrorMessage } from 'vee-validate';
import { bindDragScroll } from '@/js/functions';
export default {
  __name: 'MTagFakeInput',
  props: {
    // 顯示星星
    star: {
      type: Boolean,
      default: false
    },
    closeBtn: {
      type: Boolean,
      default: false
    },

    /**
     * @property {array} modelValue
     * @property {string} modelValue[].name
     * @property {number} modelValue[].count
     * @property {boolean} modelValue[].stared
     */
    modelValue: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    inputBind: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    h: {
      type: String,
      default: '35px'
    },
    // FormItem
    label: {
      type: String,
      default: ''
    },
    // 原生
    placeholder: {
      type: String,
      default: ''
    }
  },
  emits: ['starClick', 'itemClick', 'closeClick'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var refMultipleDisplay = ref(null);
    var noData = computed(function () {
      if (!props.modelValue) return true;
      if (!Array.isArray(props.modelValue)) return true;

      if (!props.modelValue.length) {
        return true;
      }
    });
    onMounted(function () {
      if (refMultipleDisplay.value) {
        bindDragScroll(refMultipleDisplay.value);
      }
    });
    return function (_ctx, _cache) {
      var _component_MJTags = __unplugin_components_0;

      var _component_UInput = __unplugin_components_1;

      var _component_FormItem = __unplugin_components_2;

      return _openBlock(), _createBlock(_component_FormItem, {
        class: "m-tag-fake-input"
      }, _createSlots({
        default: _withCtx(function () {
          return [_createVNode(_component_UInput, _mergeProps({
            readonly: ""
          }, {
            h: __props.h,
            placeholder: __props.placeholder
          }, {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = function ($event) {
              return _ctx.$emit('update:modelValue', $event);
            })
          }), {
            default: _withCtx(function () {
              return [_createElementVNode("div", {
                ref_key: "refMultipleDisplay",
                ref: refMultipleDisplay,
                class: "m-tag-fake-input__multiple-display"
              }, [_createVNode(_component_MJTags, {
                star: __props.star,
                gap: "10px",
                "gap-affect-outside": false,
                list: __props.modelValue,
                "close-btn": __props.closeBtn,
                onStarClick: _cache[0] || (_cache[0] = function ($event) {
                  return _ctx.$emit('starClick', $event);
                }),
                onCloseClick: _cache[1] || (_cache[1] = function ($event) {
                  _ctx.$emit('closeClick', $event);
                })
              }, {
                default: _withCtx(function (slotProp) {
                  return [_createTextVNode(" #" + _toDisplayString(slotProp.item.name) + " ", 1), slotProp.item.count > 1 ? (_openBlock(), _createElementBlock("div", _hoisted_2, " x" + _toDisplayString(slotProp.item.count), 1)) : _createCommentVNode("", true)];
                }),
                _: 1
              }, 8, ["star", "list", "close-btn"]), __props.placeholder && _unref(noData) ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(__props.placeholder), 1)) : _createCommentVNode("", true)], 512)];
            }),
            _: 1
          }, 16)];
        }),
        _: 2
      }, [__props.label ? {
        name: "label",
        fn: _withCtx(function () {
          return [_createElementVNode("div", {
            innerHTML: __props.label
          }, null, 8, _hoisted_1)];
        }),
        key: "0"
      } : undefined]), 1024);
    };
  }
};